<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          TRANSPORTISTAS
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-5"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="incluirInactivos"
          label="Incluir inactivos"
          @change="cargarLista"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="nuevoTransportista()">
          <v-icon>mdi-plus-circle-outline</v-icon> Nuevo transportista
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="search"
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn
            icon
            color="green"
            @click="editarTransportista(item.TRANSP_ID)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            title="Borrar transportista"
            icon
            color="red"
            @click="eliminarTransportista(item.TRANSP_ID)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-btn
            v-if="item.NUM_CLIENTES > 0"
            icon
            title="Clientes que usan este transportista"
            color="info"
            @click="cargarClientesTransportista(item.TRANSP_ID)"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.ACTIVO="{ item }">
          {{ item.ACTIVO == "1" ? "ACTIVO" : "INACTIVO" }}
        </template>
      </v-data-table>
    </v-card>

    <!-- dialogo transportista -->
    <v-dialog v-model="dialogTransportista" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          <span class="headline"
            >Transportista | {{ datosTransportista.TRANSP_ID }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formTransportista">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosTransportista.TRANSP_NOMBRE"
                    label="Nombre "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosTransportista.DIRECCION"
                    label="Dirección"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosTransportista.CIUDAD"
                    label="Ciudad"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-autocomplete
                    :rules="requiredRule"
                    v-model="datosTransportista.ESTADO"
                    label="Estado"
                    :items="estadosPais"
                    item-text="GEO_NAME"
                    item-value="GEO_CODE"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="pt-0 pb-0" cols="3">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosTransportista.CODIGO_POSTAL"
                    label="Código postal"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosTransportista.TELEFONO"
                    label="Teléfono"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarTransportista">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn small @click="dialogTransportista = !dialogTransportista">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo clientes transportista -->
    <v-dialog v-model="dialogCliTransp" max-width="600px">
      <v-card>
        <v-card-title>
          <v-icon left class="mb-1">mdi-account</v-icon>
          <span class="headline">Clientes que usan este transportista</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="clientesTransp"
            :headers="headClientesTransp"
            hide-default-footer
          >
            <template v-slot:item.opcion="{ item }">
              <v-btn
                icon
                small
                target="_blank"
                :to="`/clientes/${item.ENTE_ID}`"
                ><v-icon>mdi-eye</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text>
          <v-btn @click="dialogCliTransp = false" small color="blue" dark
            ><v-icon>mdi-close</v-icon>Cerrar</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "Transportistas",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Codigo", value: "TRANSP_ID" },
      { text: "Nombre", value: "TRANSP_NOMBRE" },
      { text: "Activo", value: "ACTIVO" },
    ],

    headClientesTransp: [
      { text: "Opciones", value: "opcion" },
      { text: "Cliente", value: "CLIENTE_NOMBRE" },
    ],

    clientesTransp: [],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        String(v)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "Ingrese un Email válido",
    ],
    lista: [],
    options: {},
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,
    search: null,
    incluirInactivos: false,

    dialogTransportista: false,
    datosTransportista: {
      TRANSP_ID: "",
      TRANSP_NOMBRE: "",
      ACTIVO: 1,
    },

    itemsPerPage: 20,
    nuevoTransp: false,
    estadosPais: [],

    dialogCliTransp: false,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),
    ...mapGetters("access", [""]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-transportistas");
      this.requestApi({
        method: "GET",
        data: {
          incluirInactivos: this.incluirInactivos,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.estadosPais = res.data.estados;
          this.pageCount = 10;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    nuevoTransportista() {
      this.nuevoTransp = true;
      this.datosTransportista = {
        TRANSP_ID: null,
        TRANSP_NOMBRE: "",
        ACTIVO: 1,
      };
      this.dialogTransportista = true;
    },
    editarTransportista(transpId) {
      this.nuevoTransp = false;
      this.abrirDialogTransportista(transpId);
    },
    abrirDialogTransportista(transpId) {
      this.setUrl("api/transportista");
      this.requestApi({
        method: "GET",
        data: {
          TRANSP_ID: transpId,
        },
      })
        .then((res) => {
          this.datosTransportista = res.data.datosTransp[0];
          this.estadosPais = res.data.estados;
          this.dialogTransportista = true;
        })
        .then(() => {});
    },

    eliminarTransportista(transportistaId) {
      Vue.swal({
        html: "Está seguro de eliminar el transportista ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-transportista");
          this.requestApi({
            method: "POST",
            data: {
              TRANSP_ID: transportistaId,
            },
          }).then((res) => {
            //console.log(res);
            this.cargarLista();
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },
    guardarTransportista() {
      if (!this.$refs.formTransportista.validate()) {
        return false;
      }
      if (this.nuevoTransp == true) {
        this.setUrl("api/crear-transportista");
      } else {
        this.setUrl("api/modificar-transportista");
      }

      this.requestApi({
        method: "POST",
        data: {
          transportista: this.datosTransportista,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
          this.dialogTransportista = false;
        })
        .then(() => {});
    },
    cargarClientesTransportista(transpId) {
      this.setUrl("api/clientes-transportista");
      this.requestApi({
        method: "POST",
        data: {
          transp_id: transpId,
        },
      })
        .then((res) => {
          //this.alertNotification({ param: { html: res.data.msg } });
          this.clientesTransp = res.data;
          this.dialogCliTransp = true;
        })
        .then(() => {});
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Transportistas");
    this.cargarLista();
  },
};
</script>
